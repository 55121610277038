<template>
  <svg viewBox="0 0 21 23" :width="size">
    <switch>
      <g>
        <path
          d="M19.3 8.3c-.3 0-.5-.2-.5-.5v-1c0-.4-.2-.8-.6-1.1l-7.5-4.1c-.4-.2-.8-.2-1.2 0L2.1 5.7c-.4.2-.7.6-.7 1.1v1c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-1c0-.8.4-1.5 1.1-1.9L9 .7c.7-.4 1.5-.4 2.1 0l7.5 4.1c.7.4 1.1 1.1 1.1 1.9v1c.1.4-.1.6-.4.6z"
        />
        <path
          d="M19.3 8.7c-.5 0-.9-.4-.9-.9v-1c0-.3-.2-.5-.4-.7L10.5 2c-.3-.2-.5-.2-.8 0L2.3 6.1c-.3.1-.4.4-.4.7v1c0 .5-.4.9-.9.9s-1-.4-1-.9v-1C0 5.8.5 5 1.4 4.5L8.9.4c.8-.4 1.7-.4 2.5 0l7.5 4.1c.8.5 1.4 1.3 1.4 2.3v1c-.1.5-.5.9-1 .9zM10.1 1c.3 0 .5.1.8.2l7.5 4.1c.5.3.8.8.8 1.4v1c0 .1.2.1.2 0v-1c0-.6-.4-1.2-.9-1.6l-7.5-4c-.5-.3-1.2-.3-1.7 0L1.8 5.2c-.6.3-.9.9-.9 1.6v1c0 .1.2.1.2 0v-1c0-.6.3-1.1.8-1.4l7.5-4.1c.2-.2.4-.3.7-.3zM17.6 21.5h-15c-1.2 0-2.2-1-2.2-2.1v-8.6c0-.2.1-.3.2-.4.2-.1.3-.1.5 0l8.9 4.8 8.9-4.8c.2-.1.3-.1.5 0s.2.2.2.4v8.6c.2 1.1-.8 2.1-2 2.1zm-16.2-10v7.8c0 .6.5 1.2 1.2 1.2h14.9c.7 0 1.2-.5 1.2-1.2v-7.8L10.3 16c-.2.1-.3.1-.5 0l-8.4-4.5z"
        />
        <path
          d="M17.6 21.9h-15c-1.5 0-2.6-1.1-2.6-2.6v-8.6c0-.3.2-.6.5-.8.3-.2.6-.2.9 0l8.7 4.7 8.7-4.7c.3-.2.6-.1.9 0 .3.2.5.5.5.8v8.6c0 1.4-1.2 2.6-2.6 2.6zM.9 10.6c0 .1 0 .1 0 0l-.1 8.7c0 1 .8 1.7 1.8 1.7h14.9c1 0 1.8-.8 1.8-1.7v-8.7h-.1l-9.1 4.9-9.2-4.9c.1 0 0 0 0 0zm16.7 10.3h-15c-.9 0-1.6-.7-1.6-1.6v-8.5l9.1 4.8h.1l9.1-4.8v8.5c-.1.9-.8 1.6-1.7 1.6zM1.9 12.2v7.1c0 .4.4.8.8.8h14.9c.4 0 .8-.3.8-.8v-7.1l-7.8 4.2c-.3.2-.6.2-.9 0l-7.8-4.2z"
        />
        <g>
          <path d="M6.4 9.4V7h7.4v2.4l-3.7 2z" />
          <path
            d="m10.1 11.8-4-2.2V6.7h8.1v2.9l-4.1 2.2zM6.8 9.2l3.3 1.8 3.3-1.8V7.4H6.8v1.8z"
          />
          <g>
            <path
              d="M10.1 12.3c-.1 0-.2 0-.2-.1L5.5 9.9c-.1-.1-.2-.3-.2-.5V6.6c0-.3.2-.5.5-.5h8.7c.3 0 .5.2.5.5v2.8c0 .2-.1.4-.3.4l-4.3 2.4c-.1.1-.2.1-.3.1zM6.3 9.1l3.8 2.1L14 9.1v-2H6.3v2z"
            />
            <path
              d="M5.8 6.5c-.1 0-.1.1 0 0l-.1 2.9v.1l4.4 2.4 4.3-2.4V6.6s0-.1-.1-.1H5.8zm4.3 5.2L5.8 9.4V6.7h8.5v2.7l-4.2 2.3zM6.7 8.9l3.4 1.9 3.4-1.9V7.5H6.7v1.4z"
            />
          </g>
        </g>
      </g>
    </switch>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      default: 17
    }
  }
};
</script>
